import styled from "styled-components"

import { ImageBackdrop } from "src/components/ImageBackdrop/ImageBackdrop"
import backdropImage from "src/ui/images/photo-orange-pink-tower.jpg"
import backdropImagePortrait from "src/ui/images/photo-orange-pink-tower-portrait.jpg"
import { MCard } from "src/ui/MCard/MCard"

export function ImageBackdropDemo() {
  const contents = Array.from({ length: 2 }).map((_, index) => (
    <div key={index}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris nec justo
      mauris. Integer maximus finibus magna dictum iaculis. Pellentesque non
      faucibus urna. Sed pellentesque velit eget pulvinar dictum. Aenean vel
      viverra neque. Nulla facilisi. Etiam nulla nisi, euismod eu consequat
      eget, hendrerit sit amet nulla. Aliquam auctor elit pulvinar interdum
      facilisis. Aliquam in commodo eros, nec varius elit. Nullam fringilla
      condimentum velit, eget fermentum metus dictum eget. In hac habitasse
      platea dictumst. Ut feugiat risus in metus blandit pellentesque. Fusce ac
      enim convallis, tincidunt lacus eget, feugiat augue.
    </div>
  ))

  return (
    <ImageBackdrop
      landscapeImageUrl={backdropImage}
      portraitImageUrl={backdropImagePortrait}
    >
      <StyledCard>{contents}</StyledCard>
    </ImageBackdrop>
  )
}

const StyledCard = styled(MCard)`
  max-width: 60ch;
`
